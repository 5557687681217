h1 {
  margin: 0px 0px 20px 0px;
}
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  background: #efefef;
}
.left {
  width: 250px;
  height: 100%;
  position: relative;
  z-index: 5;
  padding: 20px 0px;
  background: #565656;
}
.left a {
  color: #fff;
  padding: 10px 20px;
  font-weight: bold;
  text-decoration: none;
  font-size: 18px;
  display: block;
}
.right {
  width: calc(100% - 250px);
}
.header {
  justify-content: space-between;
  height: 60px;
  color: #222;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 4px #8c8c8c;
  z-index: 4;
  position: relative;
  font-weight: 600;
}
.content {
  padding: 20px;
}
.tickets-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tickets-header > div {
  display: flex;
  align-items: center;
}
.new-ticket {
  margin-left: 20px;
  background: #ff3902;
}
.ticket .ticket-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ticket .create-modify {
  justify-content: center;
  display: flex;
}
.ticket .create-modify > div {
  color: #ccc;
}
.ticket .create-modify > div:last-of-type {
  margin-left: 10px;
}
.ticket .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.ticket .title .id{
  color: #ccc;
  font-size: 14px;
  font-weight: 500;
}
.ticket .status{
  margin-bottom: 20px;
}
.ticket .comment > div {
  margin-bottom: 10px;
  font-weight: bold;
}
.timeline .timeline-title {
  margin-bottom: 10px;
  font-weight: bold;
}
.timeline-item {
  padding: 10px;
}
.timeline-name {
  margin-bottom: 5px;
  font-weight: bold;
}
.tickets {
  padding:0;
  margin: 0;
}
.tickets li {
  list-style-type: none;
  background: #fff;
  padding: 15px 30px;
  border: 1px solid #e4e4e4;
  margin-bottom: 20px;
}
.ticket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticket-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-decoration: none;
  color: #222;
}
.ticket-id {
  color: #ccc;
}
.ticket-create, .ticket-modify {
  font-size: 13px;
  color: #ccc;
}
.login-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #f3f3f3;
}
.login-wrapper form {
  padding: 20px;
  margin-top: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 4px #ccc;
}
input[type="text"], input[type="password"], textarea, select {
  display: block;
  margin-bottom: 10px;
  line-height: 20px;
  padding: 8px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: calc(100% - 40px);
}
select {
  width: 100%;
}
.tinkoff-form {
  margin: 70px auto 0px;
  max-width: 300px;
}
.message {
  margin-bottom: 20px;
  background: #fff;
  padding: 10px;
  border: 1px solid #ff3902;
}
button {
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  background: #222;
  color: #fff;
  display: block;
}
.modal {
  height: 100vh;
  width: 100vw;
  background-color: #0808088a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s all;
}
.modal.active {
  opacity: 1;
  pointer-events: all;
}
.modal form {
  width: 300px;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  transform: scale(0.5);
  transition: 0.4s all;
  box-shadow: 0px 1px 4px #ccc;
}
.modal.active form{
  transform: scale(1);
}
@media screen and (max-width: 475px) {
  .right {
    width: 100%;
  }
}